import { Helmet } from 'react-helmet'

import * as React from 'react'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Box, Flex } from 'grid-styled'
import CTA from '../../components/CTA'
import GalleryContainer from '../../components/GalleryContainer'
import GalleryImage from '../../components/GalleryImage'
import {
  HomeHeader,
  FormContainer,
  AlternatingContent,
  AboutContact,
  VideoContainer,
} from '..'
import Form from '../../components/Form'
import { Text, GallerySection, Content } from '../../components/styles'
import Button from '../../components/Button'
import ContentCard from '../../components/ContentCard'
import Hero from '../../components/Hero'
import { Video } from '../../components/procedures'

export default ({ data }) => (
  <Layout header="dark">
    <Helmet>
      <title>Glass Room Enclosures | Patio Extensions | Just Patios</title>
      <meta
        name="description"
        content="Professional room enclosure builders servicing South East Queensland. Custom residential glass room enclosures. Contact us today for your free quote."
      />
    </Helmet>

    <Content style={{ paddingTop: 150 }}>
      <h1>Glass Room Enclosures</h1>

      <h4>
        This option is great if you are considering making the area into a room
        at a later date.
      </h4>

      <p>
        Perfect all year round entertainment area for the whole family. With
        your new Glass Enclosure we can easily match existing house colours
        giving your home a fresh new look. We have a huge range of glass
        windows, glass sliding doors and glass swing door configurations
        available to meet your needs.
      </p>

      <p>
        Screening options from standard flyscreens, paw proof (great for pets),
        midge proof, security grille and other security options are also
        available. Maybe your entertaining area has issues with glare and
        possibly noise. Well, we can offer a range of different quality glass to
        reduce glare and also give privacy as well as lower outside noise
        levels. Contact us today and let one of our professional consultants
        help you achieve your dream!
      </p>

      <Link to="/contact-us#form">
        <Button inverted={true}>Request Your Free Quote Today!</Button>
      </Link>
    </Content>

    <GalleryContainer>
      {data.gallery.edges
        .sort((x, y) => {
          if (
            x.node.childImageSharp === null ||
            y.node.childImageSharp === null
          ) {
            return 0
          }
          const arr = x.node.childImageSharp.fixed.src.split('/')
          const number = parseInt(arr[arr.length - 1].split('.')[0])
          const arr2 = y.node.childImageSharp.fixed.src.split('/')
          const number2 = parseInt(arr2[arr2.length - 1].split('.')[0])

          return number < number2 ? -1 : 1
        })
        .map((image, i) => {
          if (image.node.childImageSharp === null) {
            return false
          }
          const arr = image.node.childImageSharp.fixed.src.split('/')
          const name = arr[arr.length - 1].split('.')[0]
          if (name === 'cover') {
            return null
          }
          return (
            <GalleryImage modal={image.node.childImageSharp.modal}>
              <Img fixed={image.node.childImageSharp.fixed} />
            </GalleryImage>
          )
        })}
    </GalleryContainer>

    <VideoContainer style={{ marginTop: -100 }}>
      <Video
        videoId="yqTGp7gMtng"
        opts={{
          height: '576',
          width: '1024',
          playerVars: {
            color: 'white',
            rel: 0,
          },
        }}
      />
    </VideoContainer>

    <AboutContact style={{ margin: '0 auto', padding: 0 }} />
  </Layout>
)

export const query = graphql`
  query GlassEnclosureQuery {
    gallery: allFile(
      filter: { relativeDirectory: { eq: "gallery/enclosures" } }
    ) {
      edges {
        node {
          childImageSharp {
            fixed(width: 400, height: 400, toFormat: JPG) {
              ...GatsbyImageSharpFixed
            }
            modal: fluid(maxWidth: 1200, quality: 98, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
